"use client";
import { Burger, Collapse } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import ScrollToTopButton from "../ScrollToTopButton";
import Navigation from "./Navigation";

interface HeaderProps {
    backgroundColor?: string;
    backgroundNavColor?: string;
    logoUrl?: string;
    logoMobileUrl?: string;
    barsUrl?: string;
    dropdownUrl?: string;
    textColor?: string;
    xUrl?: string;
    textColorHover?: string;
    arrowSrc?: string;
    arrowHoverSrc?: string;
    toggleColor?: string;
    buttonBorder?: string;
}

export default function Header({
    backgroundColor = "bg-black",
    backgroundNavColor = "bg-black",
    logoUrl = "/logoRBWhite.png",
    logoMobileUrl = "/logoRBMobileWhite.svg",
    barsUrl = "/Icons/Header/barsWhite.svg",
    dropdownUrl = "/Icons/Header/dropdownWhite.svg",
    textColor = "text-neutral-100",
    xUrl = "/Icons/Header/xwhite.svg",
    textColorHover = "text-yellow",
    arrowHoverSrc = "/Icons/Header/arrowRYellow.svg",
    arrowSrc = "/Icons/Header/arrowRWhite.svg",
    toggleColor = "black",
    buttonBorder = "border-2 border-pink",
}: HeaderProps) {
    const [opened, { toggle }] = useDisclosure();
    const router = useRouter();
    const [open, setOpen] = useState(false);
    return (
        <div className="absolute -top-1 z-50 w-full">
            <div
                className={twMerge(
                    " w-full  h-18 lg:px-20 lg:py-3 py-4 pl-4 justify-between px-3 items-center inline-flex z-50",
                    opened ? backgroundNavColor : backgroundColor,
                )}
            >
                <div className="h-full grid">
                    <button
                        type="button"
                        onClick={() => {
                            opened && toggle();
                            router.push("/");
                        }}
                    >
                        <Image
                            alt=""
                            height={50}
                            width={200}
                            className="lg:block hidden h-6 my-auto lg:mt-0 lg:w-56 lg:h-11 w-32"
                            src={opened ? "/logoRBWhite.png" : logoUrl}
                        />
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            opened && toggle();
                            router.push("/");
                        }}
                    >
                        <Image
                            alt=""
                            height={50}
                            width={100}
                            className="w-11 lg:hidden my-auto block h-11"
                            src={opened ? "/logoRBMobileWhite.svg" : logoMobileUrl}
                        />
                    </button>
                </div>
                <div className="my-auto justify-between lg:justify-start items-start lg:gap-6 gap-0 flex">
                    <button
                        onClick={() => {
                            router.push("/de/bestellen");
                        }}
                        className={twMerge(
                            "px-4 py-3 bg-white bg-opacity-20 rounded-lg justify-center items-start gap-2 flex",
                            buttonBorder,
                        )}
                        type="button"
                    >
                        <div className="w-6 h-6 justify-center items-center flex">
                            <Image
                                alt=""
                                width={20}
                                height={20}
                                className="w-6 h-6 relative flex-col justify-start items-start flex"
                                src="/Icons/favorite.svg"
                            />
                        </div>
                        <div
                            className={twMerge(
                                "text-center   text-opacity-70 text-base font-normal leading-normal",
                                opened ? "text-white" : `${textColor}`,
                            )}
                        >
                            Looking for help
                        </div>
                    </button>
                    <div className="justify-start items-start z-50 flex ">
                        <button
                            className="px-2 hidden  py-3 rounded-sm justify-start items-start lg:flex"
                            onClick={() => {
                                setOpen(!open);
                            }}
                            type="button"
                        >
                            <div
                                className={`text-center ${
                                    opened ? "text-white" : `${textColor}`
                                }  mt-[0.10rem] text-opacity-70 text-sm font-normal  uppercase leading-normal`}
                            >
                                De
                            </div>
                            <Image
                                alt=""
                                width={20}
                                height={20}
                                className={`${open && "rotate-180"} w-6 h-6 relative`}
                                src={
                                    opened
                                        ? "/Icons/Header/dropdownWhite.svg"
                                        : dropdownUrl
                                }
                            />
                        </button>
                        {open && (
                            <div className="absolute top-14 z-50 ">
                                <div className="h-16 w-16 uppercase divide-y bg-white border border-darkGray rounded-md  flex flex-col justify-center items-center ">
                                    <a href="/">De</a>
                                    <a href="/en/home">En</a>
                                </div>
                            </div>
                        )}
                        <a href={process.env.NEXT_PUBLIC_LOGIN_URL}>
                            <div className="px-2 py-3 rounded-lg justify-start items-start gap-1 hidden lg:flex">
                                <div
                                    className={twMerge(
                                        "text-center  text-opacity-70 text-base font-normal leading-normal",
                                        opened ? "text-white" : `${textColor}`,
                                    )}
                                >
                                    Login
                                </div>
                            </div>
                        </a>
                        <Burger
                            onClick={toggle}
                            opened={opened}
                            color={opened ? "white" : toggleColor}
                            className="mt-2 ml-4"
                        />
                    </div>
                </div>
                <ScrollToTopButton />
            </div>
            <div className="w-full absolute ">
                <Collapse in={opened} transitionTimingFunction="linear">
                    {opened && (
                        <Navigation
                            backgroundColor={"bg-black"}
                            textColor={"text-white"}
                            textColorHover={"text-yellow"}
                            arrowHoverSrc={arrowHoverSrc}
                            arrowSrc={arrowSrc}
                        />
                    )}
                </Collapse>
            </div>
        </div>
    );
}
