"use client";
import { Breadcrumbs, Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import navigation from "../../../public/content/navigation.json";

interface NavigationProps {
    backgroundColor?: string;
    textColor?: string;
    textColorHover?: string;
    arrowSrc?: string;
    arrowHoverSrc?: string;
}
export default function Navigation({
    backgroundColor,
    textColor,
    textColorHover,
    arrowSrc,
    arrowHoverSrc,
}: NavigationProps) {
    const [opened, { open, close }] = useDisclosure(false);
    const [subnavName, setSubnavName] = useState("");
    const [subsubnavName, setSubsubnavName] = useState("");
    const [rowThreeName, setRowThreeName] = useState("");
    const [query, setQuery] = useState("");
    const router = useRouter();
    useLockBodyScroll();

    const pathname = usePathname();
    return (
        <>
            <div
                className={twMerge(
                    " w-full py-0 px-0 h-screen z-50 sticky overflow-y-scroll",
                    backgroundColor,
                )}
            >
                <div className=" lg:flex px-3 justify-center px-auto  justify-items-center ">
                    <div className="lg:w-360 w-full  lg:h-200 lg:px-20  lg:pb-50 flex-col justify-start items-center gap-20  lg:inline-flex">
                        <div className="self-stretch w-full p-3  justify-start items-center gap-1 inline-flex">
                            {/* <div className="w-6 h-6  md:hidden justify-center items-center flex">
                                <img
                                    className="w-6 h-6 relative flex-col justify-start items-start flex"
                                    src={`${
                                        backgroundColor === "bg-darkGray"
                                            ? "/Icons/Search.svg"
                                            : "/Icons/SearchBlack.svg"
                                    } `}
                                />
                            </div>
                            <div
                                className={`${textColor} md:hidden grow shrink basis-0 text-base font-normal  leading-normal`}
                            >
                                Search
                            </div> */}
                        </div>
                        <div className="flex lg:hidden my-5 space-x-2">
                            <div>
                                <Breadcrumbs>
                                    {pathname
                                        .slice(1)
                                        .replace("de", "home")
                                        .split("/")

                                        .map((item) => {
                                            return (
                                                <p
                                                    key={item}
                                                    className={twMerge(
                                                        "capitalize",
                                                        textColor,
                                                    )}
                                                >
                                                    {item.length > 15
                                                        ? `
                                            ${item.slice(0, 10).replaceAll("-", " ")}..
                                            `
                                                        : `
                                            ${item.replaceAll("-", " ")}
                                            `}
                                                </p>
                                            );
                                        })}
                                </Breadcrumbs>
                            </div>
                        </div>

                        <div
                            className=" lg:w-340 w-full lg:px-42 px-auto justify-start items-start lg:justify-between lg:inline-flex"
                            onMouseLeave={() => {
                                setSubnavName("");
                                setSubsubnavName("");
                            }}
                        >
                            {/* 1 row */}
                            <div>
                                <div
                                    className={twMerge(
                                        "lg:w-60 w-full flex-col justify-center items-center inline-flex",
                                        subnavName ? "hidden lg:inline-flex" : "",
                                    )}
                                >
                                    {navigation.navigation.map((item) => {
                                        let Component: string | typeof Link = "a";
                                        let hrefProps = { href: "#" };
                                        if (item.url) {
                                            // Let the typescript compiler know the type of the component is now typeof Link
                                            Component = Link;
                                            hrefProps = { href: item.url };
                                        }
                                        return (
                                            <Component
                                                className="self-stretch p-3 border-b cursor-pointer border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                onMouseEnter={() => {
                                                    // If we are on iphone, do nothing
                                                    if (
                                                        navigator.userAgent.match(
                                                            /(iPod|iPhone|iPad)/,
                                                            // /(iPod|iPhone|iPad|Android|webOS|BlackBerry|Windows Phone)/,
                                                        )
                                                    ) {
                                                        return;
                                                    }

                                                    setSubnavName(item.name);
                                                }}
                                                onClick={() => {
                                                    // If there are no submenus do nothing
                                                    if (!item.subnav) {
                                                        return;
                                                    }

                                                    setSubnavName(item.name);
                                                }}
                                                key={item.name}
                                                {...hrefProps}
                                            >
                                                <div
                                                    className={twMerge(
                                                        "grow shrink basis-0 text-base font-normal leading-normal",
                                                        subnavName === item.name
                                                            ? textColorHover
                                                            : textColor,
                                                    )}
                                                >
                                                    {item.name}
                                                </div>
                                                {item.subnav && (
                                                    <Image
                                                        alt=""
                                                        width={20}
                                                        height={20}
                                                        className="w-6 h-6 relative"
                                                        src={
                                                            item.name === subnavName
                                                                ? "/Icons/Header/arrowRYellow.svg"
                                                                : "/Icons/Header/arrowRWhite.svg"
                                                        }
                                                    />
                                                )}
                                            </Component>
                                        );
                                    })}
                                </div>
                                {/* 2 row mobile */}
                                <div
                                    className={twMerge(
                                        "lg:hidden lg:w-60 w-full flex-col justify-center items-center inline-flex",
                                        subsubnavName ? "hidden" : "",
                                    )}
                                >
                                    {subnavName && (
                                        <>
                                            {navigation.navigation
                                                .find((item) => item.name === subnavName)
                                                ?.subnav?.map((item) => {
                                                    return (
                                                        <a
                                                            className="self-stretch p-3 border-b cursor-pointer border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                            onClick={() => {
                                                                setSubsubnavName(
                                                                    item.name,
                                                                );
                                                            }}
                                                            key={item.name}
                                                            href={item.url}
                                                        >
                                                            <div
                                                                className={twMerge(
                                                                    "grow shrink basis-0 text-base font-normal leading-normal",
                                                                    subsubnavName ===
                                                                        item.name
                                                                        ? textColorHover
                                                                        : textColor,
                                                                )}
                                                            >
                                                                {item.name}
                                                            </div>

                                                            {item?.subnav && (
                                                                <Image
                                                                    alt=""
                                                                    width={20}
                                                                    height={20}
                                                                    className="w-6 h-6 relative"
                                                                    src={
                                                                        item.name ===
                                                                        subsubnavName
                                                                            ? "/Icons/Header/arrowRYellow.svg"
                                                                            : "/Icons/Header/arrowRWhite.svg"
                                                                    }
                                                                />
                                                            )}
                                                        </a>
                                                    );
                                                })}
                                        </>
                                    )}
                                </div>
                                {/* 3 row mobile */}
                                <div className="lg:hidden lg:w-60 w-full flex-col justify-center items-left inline-flex">
                                    {subsubnavName && (
                                        <>
                                            {navigation.navigation
                                                .find((item) => item.name === subnavName)
                                                ?.subnav?.map((subnav) =>
                                                    subnav.name === subsubnavName ? (
                                                        subnav.subnav?.map(
                                                            (subsubnav) => (
                                                                <>
                                                                    <a
                                                                        key={
                                                                            subsubnav.name
                                                                        }
                                                                        className="self-stretch p-3 cursor-pointer border-b border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                                        href={
                                                                            subsubnav.url
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={twMerge(
                                                                                "grow shrink basis-0  text-base font-normal leading-normal",
                                                                                textColor,
                                                                            )}
                                                                        >
                                                                            {
                                                                                subsubnav.name
                                                                            }{" "}
                                                                        </div>
                                                                    </a>
                                                                    {subsubnav.name.includes(
                                                                        "Jetz",
                                                                    ) && (
                                                                        <div className="text-black p-1 mt-2 text-left bg-cyan rounded-md">
                                                                            Abo-Preis:
                                                                            CHF 68.- /
                                                                            EURO 70.-
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ),
                                                        )
                                                    ) : (
                                                        <></>
                                                    ),
                                                )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* row 2 */}
                            <div className=" hidden w-60 flex-col justify-center items-center lg:inline-flex">
                                {subnavName && (
                                    <>
                                        {/*  @ts-ignore */}
                                        {navigation.navigation
                                            .find((item) => item.name === subnavName)
                                            .subnav?.map((item) => {
                                                return (
                                                    <a
                                                        className="self-stretch cursor-pointer p-3 border-b border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                        onMouseEnter={() => {
                                                            setSubsubnavName(item.name);
                                                        }}
                                                        key={item.name}
                                                        href={item.url}
                                                    >
                                                        <div className="flex">
                                                            <div
                                                                className={twMerge(
                                                                    "grow shrink basis-0  text-base font-normal  leading-normal",
                                                                    item.name ===
                                                                        subsubnavName
                                                                        ? textColorHover
                                                                        : textColor,
                                                                )}
                                                            >
                                                                {item.name}
                                                            </div>
                                                            {item.subnav && (
                                                                <Image
                                                                    alt=""
                                                                    width={20}
                                                                    height={20}
                                                                    className="w-6 h-6 relative"
                                                                    src={
                                                                        item.name ===
                                                                        subsubnavName
                                                                            ? "/Icons/Header/arrowRYellow.svg"
                                                                            : "/Icons/Header/arrowRWhite.svg"
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </a>
                                                );
                                            })}
                                    </>
                                )}
                            </div>
                            {/* row 3 */}
                            <div
                                className=" hidden w-60 flex-col justify-center items-center lg:inline-flex"
                                onMouseLeave={() => {
                                    setSubsubnavName("");
                                }}
                            >
                                {navigation.navigation
                                    .find((item) => item.name === subnavName)
                                    ?.subnav?.map((subnav) =>
                                        subnav.name === subsubnavName ? (
                                            subnav.subnav?.map((subsubnav) => (
                                                <>
                                                    <a
                                                        key={subsubnav.name}
                                                        className="self-stretch p-3 cursor-pointer border-b border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                        href={subsubnav.url}
                                                        onMouseEnter={() => {
                                                            setRowThreeName(
                                                                subsubnav.name,
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={twMerge(
                                                                "grow shrink basis-0  text-base font-normal leading-normal",
                                                                subsubnav.name ===
                                                                    rowThreeName
                                                                    ? textColorHover
                                                                    : textColor,
                                                            )}
                                                        >
                                                            {subsubnav.name}
                                                        </div>
                                                    </a>
                                                    {subsubnav.name.includes("Jetz") && (
                                                        <div className="text-black p-1 mt-1  bg-cyan rounded-md">
                                                            Abo-Preis: CHF 68.- / EURO
                                                            70.-
                                                        </div>
                                                    )}
                                                </>
                                            ))
                                        ) : (
                                            <></>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer position="bottom" onClose={close} opened={opened} size={"xs"}>
                    <div className="block lg:hidden w-full  z-50 relative bg-white rounded-tl-lg rounded-tr-lg">
                        <div className="w-12 h-1 left-41 top-2 absolute bottom-0 bg-stone-300 rounded" />
                        <div className="w-full p-3 left-0 top-7 absolute border-b border-zinc-300 justify-start items-center gap-1 inline-flex">
                            <a
                                href="/"
                                className="grow shrink basis-0 text-neutral-800 text-base font-normal leading-normal"
                            >
                                DE
                            </a>
                        </div>
                        <div className="w-full p-3 left-0 top-19 absolute justify-start items-center gap-1 inline-flex">
                            <a
                                href="/en/home"
                                className="grow shrink basis-0 text-neutral-800 text-base font-normal leading-normal"
                            >
                                ENG
                            </a>
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    );
}
