"use client";

import Image from "next/image";

export default function ScrollToTopButton() {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="fixed right-5 bottom-10 z-40 xl:bottom-5 ">
            <button
                className="h-14 w-14 rounded-full border border-gray-400 bg-gray-200 opacity-40 hover:opacity-95"
                onClick={goToTop}
                type="button"
            >
                <Image
                    alt=""
                    width={20}
                    height={20}
                    src="/Icons/arrowUp.svg"
                    className="m-auto h-5 w-5 opacity-100"
                />
            </button>
        </div>
    );
}
